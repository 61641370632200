.address-section__wrapper {
	margin-top: 120px;
}

.address-section__heading {
	color: #fefefe;
	font-family: Code Pro Bold LC;
	font-size: 48px;
	text-transform: uppercase;
	text-align: center;
}

.address-section__city {
	color: white;
	font-family: Code Pro Bold LC;
	font-size: 22px;
	text-transform: uppercase;
	text-align: center;
}

.address-section__type-section {
	width: 100%;
	margin-top: 40px;
	margin-bottom: 80px;
}

.address-section__type-heading {
	color: #b68358;
	font-family: Code Pro Bold LC;
	font-size: 18px;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 40px;
}

.address-section__line {
	width: 100%;
	border-bottom: 1px solid #bb8c64;
	padding: 5px 0 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

.address-section__logo {
	flex-basis: 120px;
	width: 120px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 15px;
}

.address-section__logo img {
	max-width: 100%;
}

.address-section__address {
	flex-basis: calc(50% - 120px);
	flex-grow: 1;
	padding: 10px;
}

.address-section__adress--physical,
.address-section__adress--web {
	font-family: AvantGardeC;
	font-size: 18px;
}

.address-section__adress--physical {
	color: white;
}

.address-section__adress--web {
	color: #bb8c64;
}

.address-section__phones {
	flex-grow: 1;
	text-align: right;
	padding: 10px;
}

.address-section__phone {
	color: white;
	font-family: AvantGardeC;
	font-size: 18px;
	margin-left: 20px;
}

.address-section__link {
	padding: 10px;
	text-align: right;
}

.address-section__link a {
	color: #b7814f;
	font-family: AvantGardeC;
	font-size: 18px;
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.address-section__link a:hover {
	@apply --transitionOpacityEnd;
}





@media (width <= 1199px) {
	.address-section__phones {
		flex-basis: 200px;
	}

	.address-section__phone {
		display: block;
		margin-bottom: 10px;
	}
}





@media (width <= 991px) {
	.address-section__address {
		flex-basis: calc(100% - 120px);
	}

	.address-section__phones {
		flex-basis: calc(100% - 200px);
		text-align: left;
		padding-left: 110px;
	}

	.address-section__phone {
		display: inline-block;
	}
}





@media (width <= 767px) {
	.address-section__type-heading {
		padding: 0 15px;
		text-align: center;
	}

	.address-section__phones {
		flex-basis: 100%;
	}

	.address-section__link {
		flex-basis: 100%;
		text-align: left;
		padding-left: 130px;
	}
}





@media (width <= 479px) {

	.address-section__logo,
	.address-section__address,
	.address-section__phones,
	.address-section__link {
		flex-basis: 100%;
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
	}

	.address-section__phone {
		margin-left: 10px;
		margin-right: 10px;
	}
}