body {
	overflow-x: hidden;
	background-color: black;
	min-width: 320px;
}


.section-anchor {	margin-top: -80px; }
.section-wrapper { margin-top: 80px; }

@media (width <= 991px) {
	.section-anchor {	margin-top: 0px; }
	.section-wrapper { margin-top: 0px; }
}