.section-wrapper--about {
	display: flex;
	padding-top: 20px;
	padding-bottom: 40px;
}

.about-floating-block {
	margin-left: 5%;
	min-width: 408px;
	height: 408px;
	background-image: url(../img/about-floating-block.png);
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.section-wrapper--about h4 {
	color: white;
	font-size: 49px;
	font-weight: 400;
	padding-left: 40px;
}

.section-wrapper--about h4 span {
	display: block;
}

.section-wrapper--about h4 > span:first-child {
	font-family: "Code Pro LC";
}

.section-wrapper--about h4 > span:last-child {
	font-family: "Code Pro Bold LC";
}

.about-text-block {
	margin-left: 60px;
	margin-top: 40px;
	margin-right: 15px;
	max-width: 850px;
}

.section-wrapper--about h2 {
	color: #fefefe;
	font-family: "Code Pro Bold LC";
	font-size: 48px;
	font-weight: 400;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 40px;
}

.section-wrapper--about p {
	color: #fefefe;
	font-family: AvantGardeC;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 40px;
}

.section-wrapper--about h3 {
	color: #fefefe;
	font-family: AvantGardeC;
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 40px;
	margin-left: 20px;
	position: relative;
}

.section-wrapper--about h3::before {
	position: absolute;
	content: "|";
	left: -20px;
	top: 0;
	font-family: "Code Pro Light";
}





@media (width <= 1199px) {
	.about-floating-block {
		min-width: 250px;
		height: 250px;
		background-size: contain;
	}

	.section-wrapper--about h4  {
		padding-left: 30px;
		font-size: 28px;
	}
}





@media (width <= 991px) {

	.section-wrapper--about {
		position: relative;
	}

	.about-floating-block {
		position: absolute;
		top: -250px;
		right: 50px;
	}

	.about-text-block {
		margin: 0 40px;
	}
}






@media (width <= 767px) {
	.about-floating-block { display: none; }
}



@media (width <= 479px) {
	.about-text-block {
		margin: 0 15px;
	}

	.section-wrapper--about h2 { font-size: 36px; }
}