.is-compensation.only-section {
	margin-top: 177px;
}

.section-wrapper--only h2 {
	color: #fefefe;
	font-family: "Code Pro Bold LC";
	font-size: 48px;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	padding: 0 15px;
}

.only-section .type-instance {
	margin-left: 50%;
	margin-top: 150px;
	margin-bottom: 60px;
}

.only-section .type-wrapper::before {
	top: -100px;
	left: 50px;
}

.only-section .type-wrapper::after {
	/* left: 0; */
}

.type-wrapper--bentonit::after {
	background-image: url(../img/type-1.png);
	bottom: 0;
}

.type-wrapper--granuly::after {
	background-image: url(../img/type-2.png);
	background-size: contain;
	top: -200px;
}

.need-wrapper {
	background-image: url(../img/need-section-bg-2.jpg);
	background-size: cover;
	text-align: center;
	padding: 50px 0;
}

.need-instance {
	text-align: left;
	width: 500px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.need-wrapper h5 {
	color: white;
	text-transform: uppercase;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
	font-weight: 400;
	font-size: 22px;
	font-family: "Code Pro Bold LC";
	padding: 0 15px;
}

.need-wrapper p {
	position: relative;
	color: white;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, .8);
	font-weight: 400;
	font-size: 18px;
	font-family: AvantGardeC;
	margin-left: 50px;
	padding-right: 15px;
}

.need-wrapper p::before {
	position: absolute;
	content: "";
	top: 5px;
	left: -35px;
	width: 20px;
	height: 20px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAS1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////+DmQsHAAAAGHRSTlMApp3oINP038R4TDIWyq+XjINtV0EFtmK+tKkbAAAAbElEQVQY07XJVxKEQAhFUZDutoNhTDNv/ysdCsv0rd4PCg50u18cHZErRWcztsmQAeEYgNCyAJ1hhUv8KvZTDcg07JiAsNBgp74+hgW6ZQ9ITrrOZHU4FZcVG39Y7RRW5c2+antz70V8lemJ/iwGDnA2YwLTAAAAAElFTkSuQmCC');
}





@media (width >= 1200px) and (width <= 1559px) {
	.type-wrapper--granuly::after {
		top: -150px;
	}
}






@media (width <= 1199px) {
	.type-wrapper--granuly::after {
		/* top: 0px; */
	}
}





@media (width <= 991px) {
	.only-section .type-instance {
		width: 100%;
		margin-left: 0%;
		margin-top: 650px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.only-section .type-wrapper::before {
		top: -100px;
		left: 0px;
	}

	.type-wrapper--granuly::after {
		top: -200px;
		bottom: auto;
	}

	.need-wrapper {
		padding: 25px 0;
	}
}





@media (width <= 767px) {
	.only-section .type-wrapper::before {
		top: 0px;
		left: 0px;
		height: 480px;
	}

	.only-section--bentonit .type-instance {
		margin-top: calc(60vw + 100px);
	}

	.only-section--granuly .type-instance {
		margin-top: calc(70vw + 100px);
	}

	.section-wrapper--only h2 {
		font-size: 32px;
	}
}





@media (width <= 479px) {
	.only-section .type-instance {
		margin-top: 400px;
	}

	.type-wrapper--granuly::after {
		top: -250px;
		bottom: auto;
	}

}

/* langs fixes */

@media (width >= 992px) {
	.lang--en .only-section--bentonit .type-instance {
		margin-top: 200px;
		margin-bottom: 200px;
	}

	.lang--ua .only-section .type-instance {
		margin-top: 220px;
		margin-bottom: 180px;
	}

	.lang--ru .only-section .type-instance {
		margin-top: 170px;
		margin-bottom: 180px;
	}

	.only-section .type-wrapper::before,
	.only-section .type-wrapper::after {
		width: 50%;
		z-index: -1;
	}
}

@media (width >= 992px) and (width <= 1199px) {
	.lang--ua .only-section .type-instance {
		margin-top: 70px;
		margin-bottom: 150px;
	}

	.lang--en .only-section--granuly .type-instance {
		margin-top: 80px;
		margin-bottom: 100px;
	}

	.lang--ru .only-section--granuly .type-instance {
		margin-top: 40px;
		margin-bottom: 80px;
	}
}

@media (width >= 992px) and (width <= 1559px) {
	.only-section .type-wrapper::before,
	.only-section .type-wrapper::after {
		background-position: center left;
		left: -20px;
	}
}

@media (width >= 1200px) {
	.lang--en .only-section--granuly .type-instance {
		margin-top: 170px;
		margin-bottom: 200px;
	}

	.lang--ru .only-section--granuly .type-instance {
		margin-top: 120px;
		margin-bottom: 180px;
	}

	.lang--ua .only-section--granuly .type-instance {
		margin-top: 150px;
		margin-bottom: 210px;
	}
}



@media (width >= 1560px) {
	.only-section .type-wrapper::before,
	.only-section .type-wrapper::after {
		background-position: center;
	}
}

@media (width <= 320px) {
	.only-section .type-wrapper::before { top: -120px; }
	.only-section .type-wrapper::after { top: -300px; }
	.only-section--bentonit .type-instance { margin-top: 250px; }
	.only-section--granuly .type-instance { margin-top: 280px; }
}





