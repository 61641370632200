.a-finish .site-section--main::after,
/* .a-finish .section-wrapper--main .column-third--two, */
.a-finish .section-wrapper--main .column-third--three {
	opacity: 1;
}

.a-start .animation-container {
	position: absolute;
	top: 207px;
	left: 0;
	width: 100%;
	height: 807px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center top;
}

.animation-container-0 { background-image: url(../img/motion/0.png); }
.animation-container-1 { background-image: url(../img/motion/1.png); }
.animation-container-2 { background-image: url(../img/motion/2.png); }
.animation-container-3 { background-image: url(../img/motion/3.png); }
.animation-container-4 { background-image: url(../img/motion/4.png); }
.animation-container-5 { background-image: url(../img/motion/5.png); }
.animation-container-6 { background-image: url(../img/motion/6.png); }
.animation-container-7 { background-image: url(../img/motion/7.png); }
.animation-container-8 { background-image: url(../img/motion/8.png); }
.animation-container-9 { background-image: url(../img/motion/9.png); }
.animation-container-10 { background-image: url(../img/motion/10.png); }
.animation-container-11 { background-image: url(../img/motion/11.png); }
.animation-container-12 { background-image: url(../img/motion/12.png); }
.animation-container-13 { background-image: url(../img/motion/13.png); }


@media (width <= 991px) {
	.animation-container-0,
	.animation-container-1,
	.animation-container-2,
	.animation-container-3,
	.animation-container-4,
	.animation-container-5,
	.animation-container-6,
	.animation-container-7,
	.animation-container-8,
	.animation-container-9,
	.animation-container-10,
	.animation-container-11,
	.animation-container-12,
	.animation-container-13 {
		display: none;
	}
}