/* variables */
:root {
	--inactiveMenu: #25262a;
	--activeMenu: #7bbee4;
	--inactiveGreyLink: #8d8989;
	--buyNow: #68b618;
	--buyNowDark: #5ba015;
	--buyNowShadow: #4c7512;
	--buyLightGrey: #e4e4e4;
	--buyDarkGrey: #d3d5d5;
	--cartQTY: #fd9401;
	--checkoutBorder: #7bbee4;
}

/* properties */
:root {
	--transitionOpacityStart {
		will-change: opacity;
		opacity: 1;
		transition: opacity .3s;
	};
	--transitionOpacityEnd {
		opacity: .7;
		transition: opacity .3s;
	};
	--opaUpStart {
		will-change: opacity;
		opacity: .4;
		transition: opacity .3s;
	};
	--opaUpEnd {
		opacity: 1;
		transition: opacity .3s;
	};
	--transitionBackgroundColor {	transition: background-color 1s;	};
	--transitionColor {	transition: color .3s;	};
	--transitionWidth { transition: width .3s; };
	--transitionAll {	transition: all .3s; }
	--fluidBorder {
		position: absolute;
		content: "";
		right: 0;
		height: 1px;
		left: 0;
		bottom: 0;
		background: currentColor;
		opacity: .25;
		transform: scaleX(.85) translateY(.085rem);
		transition: .2s;
	};
	--fluidBorderHover {
		opacity: .75;
		transform: scaleX(.95);
	}
	--shadowMin { box-shadow: 0 0 0 rgba(0,0,0,0); }
	--shadowMax { box-shadow: 0 3px 3px rgba(0,0,0,0.2); }
	--discoloredOn {
		filter: grayscale(1) opacity(.3) contrast(0);
		transition: filter .3s;
	}
	--discoloredOff { filter: grayscale(0) opacity(1) contrast(1); }
}


/* fonts */
@font-face {
	font-family: AvantGardeC;
	src: url(../fonts/AvantGardeGothicC-Book.otf);
}

@font-face {
	font-family: "Code Pro LC";
	src: url(../fonts/CodeProLC.otf);
}

@font-face {
	font-family: "Code Pro Bold LC";
	src: url(../fonts/CodeProBoldLC.otf);
}

@font-face {
	font-family: "Code Pro Light";
	src: url(../fonts/CodeProLight.otf);
}