.site-section--need {
	background-color: black;
	background-image: url(../img/need-section-bg-1.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
	overflow: hidden;
}

.section-wrapper--need h2,
.section-wrapper--need h3 {
	font-family: "Code Pro Bold LC";
	font-size: 49px;
	font-weight: 400;
	text-transform: uppercase;
}

.section-wrapper--need h2 {
	color: #FFF;
	margin-top: 270px;
	text-align: center;
}

.section-wrapper--need h3 {
	color: #b68358;
	text-align: center;
}

.section-wrapper--need h2 span,
.section-wrapper--need h3 span {
	display: block;
}

.section-wrapper--need h2 span:first-child {
	margin-left: -200px;
}

.section-wrapper--need h3 span:first-child  {
	margin-left: -200px;
}

.need-section-block {
	width: 50%;
	margin-left: 50%;
	padding: 30px;
}

.need-section-block p {
	font-family: AvantGardeC;
	font-size: 16px;
}

.two-block-wrapper {
	position: relative;
}

.need-section-block--1 {
	margin-top: 100px;
	margin-bottom: 50px;
	background: white;
	box-shadow: 0 15px 59px rgba(0, 0, 0, 0.81);
	position: relative;
	z-index: 2;
}

.need-section-block--bg {
	position: absolute;
	top: 35px;
	left: calc(50% + 35px);
	height: 100%;
	width: 1000px;
	background-color: #b68358;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjBAMAAADs965qAAAAJFBMVEUAAAABAQEEBAVcW1xKSk1CQEJSU1VGRUg7Ozs6OTcyMzIHBwqQDskvAAAAAXRSTlMAQObYZgAAADxJREFUKM9jGPSgAVNoA6aQAYYIF6YiJqKEmDGFJmAKCRAllIAhwoqpiJEoIRZMoQJynRWAaTqRoUU3AADq6AN1SIiGIwAAAABJRU5ErkJggg==');
	z-index: 1;
}

.need-section-block--1 p {
	color: #0f0d08;
}

.need-section-block--2 p {
	color: #f8f7f6;
}

.need-section-block--3 {
	margin-bottom: 50px;
}
.section-wrapper--need h4 {
	color: white;
	font-family: "Code Pro Bold LC";
	font-size: 35px;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 25px;
}

.need-section-block--3 p {
	color: white;
	font-family: AvantGardeC;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.6;
}

.need-section-block--4-wrapper {
	background-image: url(../img/need-section-bg-2.jpg);
	background-size: cover;
	/* border-top: 1px solid #626262; */
	/* border-bottom: 1px solid #626262; */
}

.need-section-block--4 {
	display: flex;
	justify-content: space-around;
}

.need-section-block--4__column {
	width: 50%;
	flex-basis: 50%;
	text-align: left;
	padding-left: 75px;
	padding-top: 75px;
	padding-bottom: 75px;
	padding-right: 35px;
}

.need-section-block--4__column:first-child {
	/* border-right: 1px solid white; */
}

.need-section-block--4 h5 {
	color: white;
	font-family: "Code Pro Bold LC";
	font-size: 22px;
	font-weight: 400;
	text-transform: uppercase;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
	margin-bottom: 25px;
}

.need-section-block--4 p {
	color: white;
	font-family: AvantGardeC;
	font-size: 18px;
	font-weight: 400;
	position: relative;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, .8);
}

.need-section-block--4 p::before {
	position: absolute;
	content: "";
	top: 5px;
	left: -35px;
	width: 20px;
	height: 20px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAS1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////+DmQsHAAAAGHRSTlMApp3oINP038R4TDIWyq+XjINtV0EFtmK+tKkbAAAAbElEQVQY07XJVxKEQAhFUZDutoNhTDNv/ysdCsv0rd4PCg50u18cHZErRWcztsmQAeEYgNCyAJ1hhUv8KvZTDcg07JiAsNBgp74+hgW6ZQ9ITrrOZHU4FZcVG39Y7RRW5c2+antz70V8lemJ/iwGDnA2YwLTAAAAAElFTkSuQmCC');
}

.section-wrapper--need + .section-wrapper--need {
	margin-top: 0;
}





@media (width >= 992px) and  (width <= 1199px) {
	.section-wrapper--need h2 {
		margin-top: 320px;
		text-shadow: 2px 2px 2px rgba(0, 0, 0, .8);
	}

	.section-wrapper--need h3 {
		text-shadow: 1px 1px 1px rgba(255, 255, 255, 1);
	}

	.need-section-block {
		margin-left: 5%;
		width: 95%;
	}

	.need-section-block--bg {
		left: calc(5% + 35px);
	}
}





@media (width <= 991px) {
	.site-section--need {
		background-position: center 150px;
	}

	.section-wrapper--need h2 {
		margin-top: 0;
	}

	.section-wrapper--need h2,
	.section-wrapper--need h3 {
		text-align: left;
	}

	.section-wrapper--need h2 span,
	.section-wrapper--need h3 span {
		display: inline;
	}

	.section-wrapper--need h2 span:first-child,
	.section-wrapper--need h3 span:first-child {
		margin-right: 15px;
		margin-left: 0;
	}

	.two-block-wrapper {
		margin-top: 500px;
	}

	.need-section-block {
		margin-left: 5%;
		width: 95%;
	}

	.need-section-block--bg {
		left: calc(5% + 35px);
	}

	.need-section-block--4__column {
		padding: 35px;
	}

	.section-wrapper--need h2,
	.section-wrapper--need h3 {
		padding: 0 15px;
		width: calc(100% - 30px);
		font-size: 36px;
	}

	.need-section-block--3 {
		margin-left: 15px;
		margin-right: 15px;
	}
}






@media (width <= 767px) {
	.two-block-wrapper {
		margin-top: 350px;
	}

	.need-section-block--4 {
		flex-wrap: wrap;
	}

	.need-section-block--4__column {
		flex-basis: 100%;
		width: 100%;
		padding-left: 50px;
	}

	.site-section--need { background-position: center 100px; }

	.section-wrapper--need h2,
	.section-wrapper--need h3 {
		font-size: 30px;
	}
}






@media (width <= 479px) {
	.section-wrapper--need h2,
	.section-wrapper--need h3 {
		font-size: 20px;
	}

	.site-section--need {
		background-position: center 100px;
	}

	.two-block-wrapper {
		margin-top: 280px;
	}
}