.site-section--main {
	margin-top: -177px;
}

.is-compensation.site-section--main {
	margin-top: 0;
}

.site-section--main::before {
	position: absolute;
	top: 0px;
	left: calc(50% - 400px);
	width: 800px;
	max-width: 100%;
	height: 800px;
	content: "";
	pointer-events: none;
	z-index: -1;
	background-image: radial-gradient(ellipse at center, rgba(255,255,255,0.5) 0%,  rgba(255,255,255,0) 70%);
}

/* ##### a-section ##### */
.site-section--main::after {
	background-image: url(../img/main-section-cat.png);
	background-size: 50%;
	background-position: top left;
	background-repeat: no-repeat;
	position: absolute;
	top: 100px;
	left: 0;
	width: 75%;
	height:881px;
	content: "";
	pointer-events: none;
	z-index: -2;
}

@media (width >= 992px)  {
	.site-section--main::after {
		opacity: 0;
		will-change: opacity;
		transition: opacity 1s;
	}
}

.section-wrapper--main {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 200px;
}

.section-wrapper--main .column-third {
	width: 33%;
	flex-basis: 33%;
	height: 600px;
}

/* ##### a-section ##### */
.section-wrapper--main .column-third--two {
	background-image: url(../img/main-section-box.png);
	background-size: contain;
	background-repeat: no-repeat;
}

@media (width >= 992px)  {
	.section-wrapper--main .column-third--two {
		opacity: 0;
		will-change: opacity;
		transition: opacity 1s;
	}
}

/* ##### a-section ##### */
.section-wrapper--main .column-third--three {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-bottom: 100px;
	padding-left: 40px;
}

@media (width >= 992px)  {
	.section-wrapper--main .column-third--three {
		opacity: 0;
		will-change: opacity;
		transition: opacity 1s;
	}
}

.section-wrapper--main h2,
.section-wrapper--main h3 {
	color: white;
	font-size: 55px;
	font-weight: 400;
	text-transform: uppercase;
}

.section-wrapper--main h2 {
	font-family: "Code Pro Bold LC";
	margin-bottom: 0;
}

.section-wrapper--main h3 {
	margin-top: 0;
	font-family: "Code Pro Light";
	padding-right: 10px;
}

.section-wrapper--main h3 span {
	display: block;
}





@media (width >= 992px) and  (width <= 1199px) {

	.section-wrapper--main .column-third {
		height: 450px;
	}

	.site-section--main::after {
		background-size: 40%;
		top: 180px;		
	}

	.section-wrapper--main h2,
	.section-wrapper--main h3 {
		font-size: 50px;
	}

}




@media (width <= 991px) {

	.section-wrapper--main {
		flex-wrap: wrap;
		align-items: flex-start;
		width: 100%;
	}

	.site-section--main::after {
		top: 220px;
	}

	.site-section--main::before {
		top: 400px;
	}

	.column-third.column-third--one {
		height: 230px;
	}

	.section-wrapper--main .column-third--one {
		order: 1;
		width: 50%;
		flex-basis: 50%;
	}

	.section-wrapper--main .column-third--two {
		order: 3;
		width: 100%;
		flex-basis: 100%;
		background-position: center;
	}

	.section-wrapper--main .column-third--three {
		order: 2;
		width: 50%;
		flex-basis: 50%;
		padding-right: 10px;
		height: auto;
		margin-bottom: 20px;
		padding-bottom: 0;
	}
}





@media (width <= 767px) {

	.site-section--main::before {
		width: 100%;
		left: 0;
	}

	.section-wrapper--main {
		margin-top: 150px;
	}

	.section-wrapper--main h2,
	.section-wrapper--main h3 {
		font-size: 40px;
	}

	.section-wrapper--main h3 {
		padding-right: 5px;
	}

	.section-wrapper--main .column-third--two {
		margin-top: 70px;
		height: 400px;
	}
}





@media (width <= 479px) {

	.site-section--main {
		margin-bottom: 50px;
	}

	.site-section--main::before {
		width: 100%;
		top: 220px;
		left: 0;
	}

	.site-section--main::after {
		top: 270px;
		background-image: url(../img/main-section-cat--480.png);
		height: 363px;
		width: 100%;
	}

	.section-wrapper--main h2,
	.section-wrapper--main h3 {
		font-size: 30px;
	}

	.section-wrapper--main .column-third--two {
		margin-top: 40px;
	}

	.section-wrapper--main .column-third--three {
		padding-left: 0;
		padding-right: 0;
	}

	.column-third.column-third--two {
		height: 300px;
	}
}




@media (width >= 1560px) {
	.site-section--main {
		margin-bottom: 100px;
	}
}





@media (width <= 320px) {
	.site-section--main {
		margin-bottom: 20px;
	}

	.column-third.column-third--two {
		height: 320px;
		background-size: 70%;
		margin-top: 0;
	}
}