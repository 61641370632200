.site-section--types {
	padding: 50px 0;
}

.section-wrapper--types { margin-top: 140px; }

.type-wrapper {
	position: relative;
}

.type-wrapper::before {
	position: absolute;	
	width: 800px;
	height: 800px;
	content: "";
	pointer-events: none;
	z-index: -1;
	background-image: radial-gradient(ellipse at center, rgba(255,255,255,0.5) 0%,  rgba(255,255,255,0) 70%);
}

.type-wrapper::after {
	position: absolute;
	width: 800px;
	height: 800px;
	content: "";
	pointer-events: none;
	z-index: -1;
	background-repeat: no-repeat;
}

.type-wrapper--1::before {
	left: 0;
	top: -100px;
}

.type-wrapper--1::after {
	left: 0;
	bottom: -50px;
	background-image: url(../img/type-1.png);
}

.type-wrapper--2::before {
	right: 0;
	top: -30px;
}

.type-wrapper--2::after {
	right: 0;
	bottom: 0px;
	background-size: contain;
	background-image: url(../img/type-2.png);
}

.type-wrapper--3::before {
	left: 0;
	top: 50px;
}

.type-wrapper--3::after {
	left: 0;
	bottom: 100px;
	background-size: contain;
	background-image: url(../img/type-3.png);
}

.section-wrapper--types h2 {
	color: #fefefe;
	font-family: "Code Pro Bold LC";
	font-size: 48px;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.type-instance {
	width: 50%;
	margin-top: 300px;
}

.type-instance--1,
.type-instance--3 {
	margin-left: 50%;
}

.section-wrapper--types h3 {
	color: white;
	font-family: "Code Pro Bold LC";
	font-size: 22px;
	font-weight: 400;
	text-transform: uppercase;
	margin-left: 15px;
}

.section-wrapper--types p,
.section-wrapper--only p {
	color: white;
	font-family: AvantGardeC;
	font-size: 18px;
	font-weight: 400;
}

.section-wrapper--types p {
	margin-left: 15px;
}

.site-section--types .section-wrapper--types a {
	color: #121212;
	font-family: "Code Pro LC";
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	text-transform: uppercase;
	background-color: #b7814f;
	border: 1px solid #b7814f;
	border-radius: 2px;
	padding: 15px 0 15px 35px;
	text-align: center;
	width: 190px;
	display: inline-block;
	position: relative;
	text-decoration: none;
	transition: background-color .3s, color .3s;
	margin-top: 20px;
	margin-left: 15px;
}

.site-section--types .section-wrapper--types .type-instance-link--inverse {
	background-color: transparent;
	color: #FFF;
}

.site-section--types .section-wrapper--types a:hover {
	background-color: transparent;
	color: #b7814f;
}

.site-section--types .section-wrapper--types .type-instance-link--inverse:hover {
	color: #121212;
	background-color: #b7814f;
}

.site-section--types .section-wrapper--types a::before {
	position: absolute;
	content: "";
	top: 13px;
	left: 30px;
	width: 20px;
	height: 20px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAS1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////+DmQsHAAAAGHRSTlMApp3oINP038R4TDIWyq+XjINtV0EFtmK+tKkbAAAAbElEQVQY07XJVxKEQAhFUZDutoNhTDNv/ysdCsv0rd4PCg50u18cHZErRWcztsmQAeEYgNCyAJ1hhUv8KvZTDcg07JiAsNBgp74+hgW6ZQ9ITrrOZHU4FZcVG39Y7RRW5c2+antz70V8lemJ/iwGDnA2YwLTAAAAAElFTkSuQmCC');
}

.site-section--types .section-wrapper--types .type-instance-link--inverse::before {
	width: 15px;
	height: 19px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAMAAAB4HKeYAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMA4cHxq2VgXFcfEezn0MammPfYeW46LSYFu7OXFc5utqsAAACMSURBVBjTRYxZFoQgDARbUAEVxd1Zcv9rThIYrY+k672kIfgUB/PxyJw1ZaZTNFT0pwrsL0lzP8t6A63sev/u+tVi4pkgJKnAQtQH9dATjRh4XOrXSDSg4tpOvZMIoy2MNhusPK1jdZbTKldMrCNR/mzooQFjbjUQDlvUHlB8cY/CprrhpsldD25xUH5OrRA4CdFWeAAAAABJRU5ErkJggg==');
}





@media (width >= 1200px) and  (width <= 1559px) {
	.type-wrapper::after {
		width: 45%;
		background-size: contain;
		top: auto;
		/* bottom: -100px; */
	}
}





@media (width >= 992px) and  (width <= 1199px) {
	.type-wrapper::after {
		width: 45%;
		background-size: contain;
	}

	.type-wrapper--1::before {
		top: -150px;
		left: -100px;
	}

	.type-wrapper--1::after {
		bottom: -300px;
	}

	.type-instance--1 {
		margin-top: 90px;
		margin-bottom: 50px;
	}


	.type-instance--2 {
		margin-top: 130px;
		margin-bottom: 50px;
		padding-left: 20px;
	}

	.type-wrapper--2::before {
		top: -90px;
		left: 180px;
	}

	.type-wrapper--2::after {
		right: 20px;
		bottom: -220px;
	}


	.type-instance--3 {
		margin-top: 100px;
		margin-bottom: 0px;
	}

	.type-wrapper--3::before {
		top: -150px;
		left: -150px;
	}

	.type-wrapper--3::after {
		bottom: -100px;
	}

}





@media (width <= 991px) {
	.section-wrapper--types {
		margin-top: 0;
	}

	.type-wrapper::before {
		top: -100px;
		width: 100%;
		background-size: contain;
	}

	.type-wrapper::after {
		bottom: auto;
		top: -130px;
		left: 10%;
		width: 80%;
		background-size: 100%;
		background-position: center;
	}

	.type-instance {
		margin-top: 650px;
		margin-left: 0;
		width: 100%;
	}

	.type-wrapper--2::after {
		top: -150px;
	}

	.type-instance--2 {
		margin-top: 680px;
	}

	.type-instance--3 {
		margin-top: 700px;
	}
}





@media (width <= 991px) and (width >= 768px) {
	.type-wrapper::after {
		background-size: contain;
	}
}





@media (width >= 480px) and (width <= 767px) {
	.type-wrapper::after { top: -210px; }
	.type-wrapper--1::before { width: 90%; left: 10%; top: -100px; } 
	.type-instance--1 { margin-top: 500px; }
	.type-wrapper--2::before { width: 90%; left: 0; top: -50px;} 
	.type-instance--2 { margin-top: 550px; }
	.type-wrapper--3::before { width: 90%; left: 10%; top: -50px; } 
	.type-instance--3 { margin-top: 550px; }
}






@media (width <= 767px) {
	.type-instance {
		padding: 0 15px;
	}

	.type-wrapper::before {
		height: 600px;
	}
}






@media (width <= 479px) {

	.type-wrapper::before {
		top: -30px;
		height: 480px;
	}

	.type-wrapper::after {
		top: -200px;
	}

	.type-instance {
		margin-top: 450px;
	}

	.section-wrapper--types h2 {
		font-size: 36px;
	}




}